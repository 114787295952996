import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

 const mainToken =()=>{
  var token = Cookies.get('rbjt')? CryptoJS.AES.decrypt(Cookies.get('rbjt'), 'royal_bank').toString(CryptoJS.enc.Utf8):'null'
 return token
 }

export const authenticate = () => {
    if (Cookies.get('rbjt') && Cookies.get('rbsc')){ 
        try {
          const main =  mainToken() 
        if(main!=='null'){
          return true
        }else{
         // window.open('/', '_self')
          window.location.href='/'
        return false
        }
       
      } catch (error) {
      //  window.open('/', '_self')
        window.location.href='/'
        return false
      }
      }else{  
       // window.open('/', '_self')
    window.location.href='/'
    return false
  } 
    }


   export const encrypt=data=>CryptoJS.AES.encrypt(data, 'royal_bank').toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, 'royal_bank')
  return byte.toString(CryptoJS.enc.Utf8)
  } 