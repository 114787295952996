
import Cookies from 'js-cookie'
import { mainToken } from './authentication'
var CryptoJS = require('crypto-js')



export const token = Cookies.get('rbjt')?CryptoJS.AES.decrypt(Cookies.get('rbjt'), 'royal_bank').toString(CryptoJS.enc.Utf8):'null'
export const customerCode = Cookies.get('rbsc')?CryptoJS.AES.decrypt(Cookies.get('rbsc'), 'royal_bank').toString(CryptoJS.enc.Utf8):'null'

export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 

export const email = Cookies.get('rbmal')?CryptoJS.AES.decrypt(Cookies.get('rbmal'), 'royal_bank').toString(CryptoJS.enc.Utf8):'null';

 /* 
 export  const ServerUrl = 'http://192.168.64.3/React/equitybank/frontend/controller';
export  const adminImages = 'http://192.168.64.3/React/equitybank/backadmin/images';
export  const PictureUrl = 'http://192.168.64.3/React/equitybank/frontend/images';
export  const FrontEndUrl = 'http://192.168.64.3/React/equitybank/frontend/rb/index.html';   */  




export  const ServerUrlAdmin = 'https://backadmin.equityfinancialbank.com/controller';
  export  const ServerUrl = 'https://equityfinancialbank.com/controller';
export  const adminImages = 'https://backadmin.equityfinancialbank.com/images';
export  const PictureUrl = 'https://equityfinancialbank.com/images';
export  const FrontEndUrl = 'https://equityfinancialbank.com/rb/index.html';  
 




